<template>
  <div class="alarm-list">
    <pageHeaderView></pageHeaderView>
    <Card class="content">
      <div class="card-header">
        <div class="card-header-text">风险预警列表</div>
        <div class="card-header-divider"></div>
      </div>
      <div class="box-header">
        <div class="box-tools">
          <div style="display: inline-block;margin-right:15px;" @mouseenter="toggleSearch(true)" @mouseleave="toggleSearch(false)">
            <Select v-model="searchBy" class="table-search" :class="{on: tableSearchOn||searchMode||searchBy}">
              <Option value="">不限</Option>
              <Option :value="col.searchKey?col.searchKey:col.key" v-for="col in columns.filter((c)=>{return c.title && c.searchable})" :key="col.key">{{col.title}}</Option>
            </Select>&nbsp;
            <Input v-model="keyword" ref="tableSearch" placeholder="搜索关键字" @on-focus="toggleSearchMode" @on-blur="toggleSearchMode" class="table-search" :class="{on: tableSearchOn||searchMode||keyword}"></Input>&nbsp;
            <Button type="default" icon="ios-search" @click="search"></Button>
          </div>
          <Button-group>
            <Button type="default" icon="md-add" @click="modalNew=true">新增</Button>
            <Button type="default" icon="md-refresh" @click="refreshList">刷新</Button>
          </Button-group>
        </div>
      </div>
      <div class="box-body">
        <Table stripe :columns="columns" :data="dataViewPage" ref="table" @on-selection-change="selectRowChange"></Table>
        <div style="margin: 10px;overflow: hidden">
          <Button type="primary" size="large" @click="exportData" :disabled="!rowSelected"><Icon type="ios-download-outline"></Icon> 导出原始数据</Button>
          <div style="float: right;">
            <Page :total="data.length" :page-size-opts="[10,20,50,100]" @on-change="changePage" @on-page-size-change="changePageSize" :current="pageCurrent" show-sizer></Page>
          </div>
        </div>
        <Modal v-model="modalDelete" width="360">
          <p slot="header" style="color:#f60;text-align:center">
            <Icon type="information-circled"></Icon>
            <span>删除确认</span>
          </p>
          <div style="text-align:center">
            <p>是否继续删除？</p>
          </div>
          <div slot="footer">
            <Button type="error" size="large" long :loading="modal_loading" @click="del">删除</Button>
          </div>
        </Modal>
      </div>
    </Card>
    <alarmAdd :show="modalNew" @close="closeAlarmNew" @postAdd="refreshList"></alarmAdd>
    <alarmDetail :show="modalDetail" @close="closeAlarmDetail" :dataSelected="dataSelected"></alarmDetail>
  </div>
</template>

<script>
import alarmAdd from './../project/ProjectAlarmAdd'
import alarmDetail from './../project/ProjectAlarmDetail'
import pageHeaderView from '../public/PageHeader'

export default {
  name: 'alarm-list',
  components: {
    pageHeaderView,
    alarmDetail,
    alarmAdd
  },
  data () {
    return {
      datatableData: [],
      loading: false,
      dataSelected: null,
      modal_loading: false,
      modalDelete: false,
      modalDisable: false,
      modalDetail: false,
      modalNew: false,
      rowSelected: false,
      rowSelectedList: [],
      pageCurrent: 1,
      pageSize: 10,
      keyword: '',
      searchBy: '',
      searchMode: false,
      tableSearchOn: false,
      columns: [
        { type: 'selection', width: 60, align: 'center' },
        {
          title: '项目名称',
          key: 'ProjectName',
          width: 320,
          ellipsis: true,
          sortable: true,
          searchable: true,
          sortMethod: (a, b, type) => {
            if (type === 'asc') {
              return a.localeCompare(b, 'zh-CN')
            } else {
              return b.localeCompare(a, 'zh-CN')
            }
          },
          render: (h, params) => {
            return h('router-link', {
              props: {
                to: '/projects/view/' + params.row.ProjectId
              }
            }, params.row.ProjectName)
          }
        },
        {
          title: '状态',
          key: 'IsActive',
          width: 80,
          ellipsis: true,
          filters: [
            {
              label: '未处理',
              value: 1
            },
            {
              label: '已关闭',
              value: 0
            }
          ],
          filterMultiple: true,
          filterMethod (value, row) {
            return row.IsActive === value
          },
          sortMethod: (a, b, type) => {
            if (type === 'asc') {
              return a.localeCompare(b, 'zh-CN')
            } else {
              return b.localeCompare(a, 'zh-CN')
            }
          },
          render: (h, params) => {
            if (params.row.IsActive) {
              return h('Tag', {
                props: {
                  // type: 'dot',
                  color: 'red'
                }
              }, '未处理')
            } else {
              return h('Tag', {
                props: {
                  // type: 'dot',
                  color: 'default'
                }
              }, '已关闭')
            }
          }
        },
        {
          title: '等级',
          key: 'Level',
          sortable: true,
          searchable: true
        },
        {
          title: '投诉时间',
          key: 'Time',
          width: 160,
          sortable: true,
          searchable: true,
          render: (h, params) => {
            return h('span', null, this.$moment(params.row.Time).format('YYYY-MM-DD HH:mm:ss'))
          }
        },
        {
          title: '操作',
          key: 'action',
          fixed: 'right',
          width: 120,
          render: (h, params) => {
            return h('Button-group', [
              h('Button', {
                props: {
                  type: 'default',
                  size: 'small'
                },
                style: {
                },
                on: {
                  click: () => {
                    this.dataSelected = params.row
                    this.modalDetail = true
                    // this.$router.push({ path: '/complaint/view/' + params.row.Id })
                  }
                }
              }, '详情')
            ])
          }
        }
      ],
      dataRaw: [],
      data: [],
      dataViewPage: []
    }
  },
  watch: {
    'data': function () {
      let vmInstance = this
      this.dataViewPage = this.data.filter(function (item, index) {
        return index < vmInstance.pageSize * vmInstance.pageCurrent && index >= vmInstance.pageSize * (vmInstance.pageCurrent - 1)
      })
    },
    'pageCurrent': function () {
      let vmInstance = this
      this.dataViewPage = this.data.filter(function (item, index) {
        return index < vmInstance.pageSize * vmInstance.pageCurrent && index >= vmInstance.pageSize * (vmInstance.pageCurrent - 1)
      })
    },
    'pageSize': function () {
      let vmInstance = this
      this.dataViewPage = this.data.filter(function (item, index) {
        return index < vmInstance.pageSize
      })
    }
  },
  mounted () {
    this.$store.dispatch('setPageStore', {
      pageHeader: '风险预警',
      pageDescr: '全部预警列表'
    })
    this.initDataTable()
  },
  methods: {
    initDataTable () {
      this.$Spin.show()
      this.$Api.Project.getAllAlarms().then((respBody) => {
        this.$Spin.hide()
        this.dataRaw = respBody.Alarms.sort((a, b) => {
          return b.Id - a.Id
        })
        this.data = respBody.Alarms.sort((a, b) => {
          return b.Id - a.Id
        })
        this.pageSize = 10
        this.pageCurrent = 1
      })
      .catch(err => {
        this.$Spin.hide()
        this.$Notice.error({
          title: '获取列表失败!',
          desc: err.message
        })
      })
    },
    getContent (row) {
      let content = ''
      if (row.Content.length >= 30) {
        content = row.Content.substr(0, 30) + '...'
      } else {
        content = row.Content
      }
      return content
    },
    closeAlarmNew () {
      this.modalNew = false
    },
    closeAlarmDetail () {
      this.modalDetail = false
    },
    selectRowChange (selection) {
      if (selection.length > 0) {
        this.rowSelected = true
        this.rowSelectedList = selection
      } else {
        this.rowSelected = false
        this.rowSelectedList = []
      }
    },
    changePage (page) {
      this.pageCurrent = page
    },
    changePageSize (pageSize) {
      this.pageSize = pageSize
    },
    exportData () {
      this.$refs.table.exportCsv({
        filename: 'zztdata.csv',
        columns: this.columns,
        data: this.rowSelectedList
      })
    },
    del () {
      this.modal_loading = true
      setTimeout(() => {
        this.modal_loading = false
        this.modalDelete = false
        this.$Message.success('删除成功')
      }, 2000)
    },
    refreshList () {
      // refreshList()
      this.initDataTable()
      this.searchBy = ''
      this.keyword = ''
      this.searchMode = false
      this.tableSearchOn = false
    },
    toggleSearchMode () {
      this.searchMode = !this.searchMode
    },
    toggleSearch (val) {
      this.tableSearchOn = val
    },
    search () {
      let cols = this.columns.filter((col) => {
        return col.searchable
      })
      this.data = this.dataRaw.filter((item) => {
        if (this.searchBy) {
          let content = this.getSearchQueryContent(item, this.searchBy)
          return content.toString().indexOf(this.keyword) >= 0
        } else {
          let found = false
          for (let i = 0; i < cols.length; i++) {
            let col = cols[i]
            let content = this.getSearchQueryContent(item, col.searchKey ? col.searchKey : col.key)
            if (content && (content.toString().indexOf(this.keyword) >= 0)) {
              found = true
            }
          }
          return found
        }
      })
      this.pageCurrent = 0
      this.pageCurrent = 1
    },
    getSearchQueryContent (item, key) {
      if (!key) {
        return ''
      }
      let qArr = []
      let qArrTemp = key.split('.')
      qArrTemp.forEach((q) => {
        if (q.indexOf(']')) {
          let subArr = q.replace(/]/g, '').split('[')
          subArr.forEach((sub) => {
            qArr.push(sub)
          })
        } else {
          qArr.push(q)
        }
      })
      let content = item
      qArr.forEach((query) => {
        if (content[query]) {
          content = content[query]
        } else {
          content = ''
        }
      })
      return content
    }
  }
}
</script>

<style lang="less" scoped>
.content {}
</style>
